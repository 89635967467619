
// Generated during bootstapping via pathsOnSiteTracker.ts
  
export const allFiles = ["/docs/handbook/declaration-files/consumption.html",
"/docs/handbook/declaration-files/by-example.html",
"/docs/handbook/declaration-files/introduction.html",
"/docs/handbook/declaration-files/do-s-and-don-ts.html",
"/docs/handbook/declaration-files/deep-dive.html",
"/docs/handbook/nightly-builds.html",
"/docs/handbook/declaration-files/library-structures.html",
"/docs/handbook/declaration-files/publishing.html",
"/docs/handbook/declaration-files/templates.html",
"/docs/handbook/typescript-in-5-minutes.html",
"/docs/handbook/typescript-in-5-minutes-oop.html",
"/docs/handbook/typescript-from-scratch.html",
"/docs/handbook/typescript-in-5-minutes-func.html",
"/docs/handbook/basic-types.html",
"/docs/handbook/classes.html",
"/docs/handbook/functions.html",
"/docs/handbook/generics.html",
"/docs/handbook/literal-types.html",
"/docs/handbook/interfaces.html",
"/docs/handbook/2/basic-types.html",
"/docs/handbook/unions-and-intersections.html",
"/docs/handbook/2/modules.html",
"/docs/handbook/2/classes.html",
"/docs/handbook/2/everyday-types.html",
"/docs/handbook/intro.html",
"/docs/handbook/2/functions.html",
"/docs/handbook/2/narrowing.html",
"/docs/handbook/2/type-declarations.html",
"/docs/handbook/2/understanding-errors.html",
"/docs/handbook/2/objects.html",
"/docs/handbook/declaration-files/dts-from-js.html",
"/docs/handbook/intro-to-js-ts.html",
"/docs/handbook/jsdoc-supported-types.html",
"/docs/handbook/type-checking-javascript-files.html",
"/docs/handbook/compiler-options-in-msbuild.html",
"/docs/handbook/project-references.html",
"/docs/handbook/tsconfig-json.html",
"/docs/handbook/configuring-watch.html",
"/docs/handbook/declaration-merging.html",
"/docs/handbook/esm-node.html",
"/docs/handbook/advanced-types.html",
"/docs/handbook/decorators.html",
"/docs/handbook/iterators-and-generators.html",
"/docs/handbook/enums.html",
"/docs/handbook/jsx.html",
"/docs/handbook/mixins.html",
"/docs/handbook/module-resolution.html",
"/docs/handbook/compiler-options.html",
"/docs/handbook/namespaces-and-modules.html",
"/docs/handbook/modules.html",
"/docs/handbook/symbols.html",
"/docs/handbook/triple-slash-directives.html",
"/docs/handbook/namespaces.html",
"/docs/handbook/type-inference.html",
"/docs/handbook/type-compatibility.html",
"/docs/handbook/release-notes/typescript-1-1.html",
"/docs/handbook/utility-types.html",
"/docs/handbook/release-notes/typescript-1-3.html",
"/docs/handbook/variable-declarations.html",
"/docs/handbook/release-notes/typescript-1-4.html",
"/docs/handbook/release-notes/typescript-1-5.html",
"/docs/handbook/release-notes/typescript-1-7.html",
"/docs/handbook/release-notes/typescript-1-6.html",
"/docs/handbook/release-notes/typescript-1-8.html",
"/docs/handbook/release-notes/typescript-2-2.html",
"/docs/handbook/release-notes/typescript-2-1.html",
"/docs/handbook/release-notes/typescript-2-5.html",
"/docs/handbook/release-notes/typescript-2-4.html",
"/docs/handbook/release-notes/typescript-2-3.html",
"/docs/handbook/release-notes/typescript-2-0.html",
"/docs/handbook/release-notes/typescript-2-6.html",
"/docs/handbook/release-notes/typescript-2-7.html",
"/docs/handbook/release-notes/typescript-2-9.html",
"/docs/handbook/release-notes/typescript-3-1.html",
"/docs/handbook/release-notes/typescript-2-8.html",
"/docs/handbook/release-notes/typescript-3-0.html",
"/docs/handbook/release-notes/typescript-3-2.html",
"/docs/handbook/release-notes/typescript-3-5.html",
"/docs/handbook/release-notes/typescript-3-3.html",
"/docs/handbook/release-notes/typescript-3-4.html",
"/docs/handbook/release-notes/typescript-3-6.html",
"/docs/handbook/release-notes/typescript-3-8.html",
"/docs/handbook/release-notes/typescript-3-7.html",
"/docs/handbook/release-notes/typescript-3-9.html",
"/docs/handbook/release-notes/typescript-4-1.html",
"/docs/handbook/release-notes/typescript-4-0.html",
"/docs/handbook/release-notes/typescript-4-2.html",
"/docs/handbook/release-notes/typescript-4-3.html",
"/docs/handbook/release-notes/typescript-4-5.html",
"/docs/handbook/release-notes/typescript-4-6.html",
"/docs/handbook/release-notes/typescript-4-8.html",
"/docs/handbook/release-notes/typescript-4-7.html",
"/docs/handbook/release-notes/typescript-4-4.html",
"/docs/handbook/angular.html",
"/docs/handbook/asp-net-core.html",
"/docs/handbook/release-notes/typescript-4-9.html",
"/docs/handbook/babel-with-typescript.html",
"/docs/handbook/integrating-with-build-tools.html",
"/docs/handbook/dom-manipulation.html",
"/docs/handbook/gulp.html",
"/docs/handbook/declaration-files/templates/global-plugin-d-ts.html",
"/docs/handbook/migrating-from-javascript.html",
"/docs/handbook/declaration-files/templates/global-modifying-module-d-ts.html",
"/docs/handbook/typescript-tooling-in-5-minutes.html",
"/docs/handbook/declaration-files/templates/global-d-ts.html",
"/docs/handbook/declaration-files/templates/module-function-d-ts.html",
"/docs/handbook/declaration-files/templates/module-plugin-d-ts.html",
"/docs/handbook/declaration-files/templates/module-class-d-ts.html",
"/docs/handbook/declaration-files/templates/module-d-ts.html",
"/docs/handbook/2/conditional-types.html",
"/docs/handbook/2/generics.html",
"/docs/handbook/2/indexed-access-types.html",
"/docs/handbook/2/mapped-types.html",
"/docs/handbook/2/keyof-types.html",
"/docs/handbook/2/template-literal-types.html",
"/docs/handbook/react.html",
"/docs/handbook/2/typeof-types.html",
"/docs/handbook/2/types-from-types.html",
"/tsconfig",
"/glossary",
"/play",
"/cheatsheets",
"/community",
"/download",
"/empty",
"/",
"/tools",
"/why-create-typescript",
"/docs/bootstrap",
"/docs/",
"/docs/handbook/",
"/branding/",
"/dev/bug-workbench/",
"/dev/playground-plugins/",
"/dev/sandbox/",
"/dev/twoslash/",
"/dev/typescript-vfs/",]